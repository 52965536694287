import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListadoAuditoriasComponent } from './listado-auditorias/listado-auditorias.component';

const routes: Routes = [
  {
    path: '',
    component: ListadoAuditoriasComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuditoriasRoutingModule { }
