import { TipoDispositivo, TIPOS_DISPOSITIVOS } from 'modelos/src';

export const version = '1.0.0';

const tipoDispositivosImportados: TipoDispositivo[] = TIPOS_DISPOSITIVOS;
export const tipoDispositivos: TipoDispositivo[] = [
  'Bomba de Cisterna',
  'Bomba Sumergible',
  'Caravana',
  'Caravana MyT',
  'Estacion de Bombeo',
  'Estacion de Bombeo V2',
  'Estacion Meteorologica',
  'Freatimetro',
  'Lanza de Humedad',
  'Nivel de Canal',
  'Nivel de Cisterna',
  'Nivel de Comederos',
  'Nivel de Láminas de Agua',
  'Nivel de Represas',
  'Pluviometro',
  'Sensor de CO2',
  'Sensor Humedad de Suelo',
  'Silobolsa',
  'Tracker',
  'Sensor RPM',
];
