<mat-progress-bar [mode]="loading ? 'indeterminate' : 'determinate'" color="accent"></mat-progress-bar>

<div class="mat-elevation-z8" style="margin: 1em">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla fxFlex [search]="search" [datos]="[cliente]" (onFilterChange)="cambioFiltro($event)">
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select [(columns)]="columnas" [columnNames]="nombreColumnas" [saveName]="nombreTabla">
    </app-column-select>
  </div>

  <!-- Tabla -->
  <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)">
    <!-- Columna Cliente -->
    <ng-container matColumnDef="cliente">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Cliente
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.cliente?.nombre }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna DevEUI -->
    <ng-container matColumnDef="deveui">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        DevEUI
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.deveui }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Device Name -->
    <ng-container matColumnDef="deviceName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Device Name
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="link" [routerLink]="row._id">{{ row.deviceName }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Action Auditoria -->
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Acciones
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.action }}</span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
  <mat-paginator-goto [length]="totalCount" [pageSize]="helper.getPageSize(nombreTabla)"
    [pageSizeOptions]="helper.pageSizeOptions" [showFirstLastButtons]="true"
    (page)="pageEvent($event)"></mat-paginator-goto>
</div>