import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { AuditoriasRoutingModule } from './auditorias.routing.module';
import { ListadoAuditoriasComponent } from './listado-auditorias/listado-auditorias.component';

@NgModule({
  declarations: [
    ListadoAuditoriasComponent
  ],
  imports: [CommonModule, AuditoriasRoutingModule, AuxiliaresModule],
})
export class AuditoriasModule { }
