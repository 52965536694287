import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import {
  ICliente,
  IAuditoria,
  IFilter,
  IListado,
  IQueryParam,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import {
  IFiltroTabla,
  IRegExpSearch,
} from '../../../auxiliares/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-listado-auditorias',
  templateUrl: './listado-auditorias.component.html',
  styleUrls: ['./listado-auditorias.component.scss'],
})
export class ListadoAuditoriasComponent implements OnInit {
  public loading = true;
  public usarColores = localStorage.getItem('usarColores') ? true : false;

  // TABLA
  public nombreTabla = 'admin-agro-v2-auditorias';
  public dataSource = new MatTableDataSource<IAuditoria>([]);
  public totalCount = 0;
  public columnas: string[] = [
    'deveui',
    'deviceName',
    'action',
  ];
  public nombreColumnas: string[] = [
    'DevEUI',
    'Device Name',
    'Acción',
  ];
  public search: IRegExpSearch = {
    fields: ['action'],
  };
  public cliente: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idCliente',
    },
    label: 'Cliente',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };

  // QUERY+
  private populate = [
    {
      path: 'cliente',
      select: 'nombre admin',
    },
    {
      path: 'usuario',
      select: 'nombre',
    },
    {
      path: 'loteDispositivo',
      select: 'nombre',
    },
    {
      path: 'tipoDispositivo',
      select: 'nombre',
    },
    {
      path: 'loraServer',
      select: 'nombre',
    },
  ];
  public queryParams: IQueryParam = {
    page: 0,
    limit: +this.helper.getPageSize(this.nombreTabla),
    sort: 'deviceName',
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public auditorias$?: Subscription;
  public clientes$?: Subscription;
  constructor(
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService,
  ) { }

  // ################################# TABLA ######################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.setPageSize(event, this.nombreTabla);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }
  // Listar
  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.auditorias$?.unsubscribe();
    this.auditorias$ = this.listadosService
      .subscribe<IListado<IAuditoria>>('auditorias', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(`listado de auditorias`, data);
      });
    await this.listadosService.getLastValue('auditorias', this.queryParams);
  }

  private async listarClientes(): Promise<void> {
    const query: IQueryParam = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.cliente.elementos = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([
      this.listar(),
      this.listarClientes(),
    ]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.auditorias$?.unsubscribe();
    this.clientes$?.unsubscribe();
  }
}
